.popover {
    width: 336px;
}

:global(#topPopover .ant-popover-inner-content) {
    padding: 0;
}

/* :global(#topPopover .ant-popover-inner-content .ant-tabs-nav-scroll) {
    text-align: center;
} */

:global(#topPopover .ant-popover-inner-content .ant-tabs-bar) {
    margin-bottom: 4px;
}


.list {
    max-height: 400px;
    overflow: auto;
}
.item {
    transition: all 0.3s;
    overflow: hidden;
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px;
}
  
.meta {
    width: 100%;
}
.avatar {
    background: #fff;
    margin-top: 4px;
    margin-bottom: 4px;
}
  
.read {
    opacity: 0.4;
}
.list:last-child {
    border-bottom: 0;
}
.item:hover {
    background: rgb(230, 247, 255);
}
.title {
    font-weight: normal;
    margin-bottom: 8px;
}
.description {
    font-size: 12px;
    line-height: 1.5;
}
.datetime {
    font-size: 12px;
    margin-top: 4px;
    line-height: 1.5;
}
.extra {
    float: right;
    color: rgba(0, 0, 0, 0.45);
    font-weight: normal;
    margin-right: 0;
    margin-top: -1.5px;
}
.clear {
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 0 0 4px 4px;
    border-top: 1px solid #e8e8e8;
    transition: all 0.3s;
    cursor: pointer;
}
.clear:hover {
    color: rgba(0, 0, 0, 0.85);
}
.operate {
    width: 32px;
    text-align: center;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
}
